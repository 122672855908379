import AccountDTO from "@/models/AccountDTO";
import {BlockReason} from "@/models/enum/BlockReason";

export default interface UserDTO {
  id: number
  name: string
  email: string
  lang: string|null
  role: string
  isBlocked: boolean
  blockReason: BlockReason|null
  blockedById: null|number
  isManualWithdrawals: boolean
  inviteToken: string|null
  is2faEnabled: boolean
  comment: string|null
  permissions: string[]
  appId: null|number
  timezone: string

  _accounts: AccountDTO[]
}

export const SystemUser = {
  id: 0,
  name: 'system',
  email: '',
  role: 'SYSTEM',
  isBlocked: false,
  blockReason: null,
  blockedById: null,
  inviteToken: null,
  is2faEnabled: false,
  isManualWithdrawals: false,
  comment: '',
  permissions: [],
  appId: null,
  timezone: '',
  lang: '',
  _accounts: [],
} as UserDTO