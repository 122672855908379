import {reactive} from "@vue/reactivity";
import ValidationErrorDTO from "@/models/ValidationErrorDTO";
import MoneyDTO from "@/models/MoneyDTO";
import OrderByDTO from "@/models/OrderByDTO";

class CommonHelper {
  private translations: any = {};
  private fallbackTranslations: any = {};
  private phoneCodes: object[] = []

  private copyState = reactive({
    timeouts: {} as any,
  });

  private sensitiveData = reactive({
    visible: false as boolean
  });

  private formErrors = reactive({
    values: {} as any,
  });

  public sum = (a: MoneyDTO|null, b: MoneyDTO): MoneyDTO => {
    if (!a) {
      return b;
    }

    if (a.currency !== b.currency) {
      throw new Error("Currencies must be equals for sum operation")
    }

    return {
      amount: (parseFloat(a.amount) + parseFloat(b.amount)).toString(),
      currency: a.currency,
      subunit: a.subunit
    } as MoneyDTO
  }

  public sub = (a: MoneyDTO|null, b: MoneyDTO): MoneyDTO => {
    if (!a) {
      return b;
    }

    if (a.currency !== b.currency) {
      throw new Error("Currencies must be equals for sum operation")
    }

    return {
      amount: (parseFloat(a.amount) - parseFloat(b.amount)).toString(),
      currency: a.currency,
      subunit: a.subunit
    } as MoneyDTO
  }

  public currencyIcon(currency:string) {
    if (currency === 'USDT') {
      return require(`@/assets/img/currencies/USDT_TRC20.svg`);
    }
    try {
      return require(`@/assets/img/currencies/${currency}.svg`);
    } catch (e) {
      return require(`@/assets/img/currencies/unknown.svg`);
    }

  }

  public flattenObject(obj: any, prefix = '', res: any = {}) {
    for (let key in obj) {
      let newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.flattenObject(obj[key], newKey, res);
      } else {
        res[newKey] = obj[key];
      }
    }
    return res;
  }

  public setPhoneCodes(items: Object)
  {
    this.phoneCodes = [];
    for (let pattern of Object.values(items)) {
      let filteredPattern = (pattern as string).replace(/\D/g, '');
      this.phoneCodes.push({
        pattern,
        filteredPattern
      })
    }
  }

  public handleErrors = (name: string, e: any) => {
    if (e.response && e.request && e.request.status === 400 && e.response.data) {
      this.setErrors(name, e.response.data)
    } else {
      console.log(e);
    }
  }

  public setErrors = (name: string, data: ValidationErrorDTO[]) => {
    this.formErrors.values[name] = {};
    for (const error of data) {
      if (!this.formErrors.values[name].hasOwnProperty(error.field)) {
        this.formErrors.values[name][error.field] = [];
      }
      this.formErrors.values[name][error.field].push(error)
    }
  }

  public clearErrors(name: string) {
    this.formErrors.values[name] = {};
  }

  public getErrors(name: string, path: string) {
    if (!this.formErrors.values.hasOwnProperty(name)) {
      return [];
    }

    if (!this.formErrors.values[name].hasOwnProperty(path)) {
      return [];
    }

    return this.formErrors.values[name][path]
  }

  public getPhonePattern(phone: string): string|null {
    let filteredPhone = phone.replace(/\D/g, '');
    for (let code of this.phoneCodes) {
      if (filteredPhone.startsWith((code as any).filteredPattern)) {
        return (code as any).pattern
      }
    }

    return null;
  }

  public generateRandomString(len: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < len; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }

  public toggleOrderBy(orderBy: OrderByDTO, field: string) {
    if (orderBy.field !== field) {
      orderBy.direction = 'asc'
    } else {
      if (orderBy.direction === 'asc') {
        orderBy.direction = 'desc'
      } else {
        orderBy.direction = 'asc'
      }
    }
    orderBy.field = field
  }

  public orderByClass(orderBy: OrderByDTO, field: string) {
    if (orderBy.field !== field) {
      return {'fa-sort': true}
    }

    if (orderBy.direction === 'asc') {
      return {'fa-sort-up': true}
    }

    return {'fa-sort-down': true}
  }

  private resolveUserLang(): string
  {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang ?? window.navigator.language ?? (window.navigator as any).browserLanguage ?? (window.navigator as any).userLanguage;

    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1) {
      shortLang = shortLang.split('-')[0];
    }

    if (shortLang.indexOf('_') !== -1) {
      shortLang = shortLang.split('_')[0];
    }

    console.log('User lang: ' + shortLang)
    return shortLang ?? 'en';
  }

  public setLang(lang: string|null = null)
  {
    if (!lang) {
      lang = this.resolveUserLang()
    }

    if (!['en', 'ru'].includes(lang)) {
      lang = 'en';
    }

    const items = require(`@/i18n/${lang}.json`)
    const fallback = require(`@/i18n/en.json`)
    this.translations = this.flattenObject(items);
    this.fallbackTranslations = this.flattenObject(fallback);
    window.userLang = lang
    console.log('Language set to ' + lang)
  }


  public trans = (key: string, params: any = {}, fallback: string|null = null) => {
    let msg = this.translations[key] ?? this.fallbackTranslations[key] ?? fallback ?? key;

    if (params) {
      for (let paramName in params) {
        msg = msg.replaceAll(`{${paramName}}`, params[paramName])
      }
    }

    return msg;
  }

  public payMethodIcon = (method: string): any => {
    return `/img/methods/${method}.png`;
  }

  private fallbackCopyTextToClipboard = (obj: any, text: string) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    obj.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      obj.removeChild(textArea);
      return successful;

    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    obj.removeChild(textArea);
    return 0
  }

  public isSensitiveDataVisible = () => {
    return this.sensitiveData.visible;
  }

  public percentToFloat(value: any): string {
    value = parseFloat(value);
    if (isNaN(value)) {
      value = 0
    }

    return (value / 100).toString();
  }

  public floatToPercent(value: string): number {
    return Math.round(parseFloat(value) *100 * 1000)/1000;
  }

  public showSensitiveData = () => {
    this.sensitiveData.visible = true
    document.body.classList.add('sensitive-data--visible')
  }

  public hideSensitiveData = () => {
    this.sensitiveData.visible = false
    document.body.classList.remove('sensitive-data--visible')
  }

  public initCopyHints = (): void => {
    this.copyState.timeouts = {};
  }

  public copyHintVisible = (hint: string): boolean => {
    return !!this.copyState.timeouts[hint];
  }

  public copy = (obj: any, value: string, hint: string): void => {
    helper.copyTextToClipboard(obj, value);
    if (this.copyState.timeouts[hint]) {
      clearTimeout(this.copyState.timeouts[hint])
    }
    this.copyState.timeouts[hint] = setTimeout(() => {
      this.copyState.timeouts[hint] = null;
    }, 1000);
  }

  public copyTextToClipboard = (obj: any, text: string) => {
    if (!navigator.clipboard) {
      return this.fallbackCopyTextToClipboard(obj, text);
    }
    navigator.clipboard.writeText(text).then(function() {
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  isValidationErrors(e: any): boolean {
    return e.response && e.response.status === 400 && e.response.data;
  }

  getValidationErrors(e: any): ValidationErrorDTO[] {
    if (this.isValidationErrors(e)) {
      return e.response.data as ValidationErrorDTO[]
    }

    return [];
  }
}

export const helper = new CommonHelper();
export const trans = helper.trans;

export function debounce<T extends (...args: any[]) => void>(fn: T, delay: number): T {
  let timeoutID: number | null = null;

  return function (this: any, ...args: any[]) {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }

    timeoutID = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  } as T;
}

