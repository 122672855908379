<template>
  <div id="app">
    <div v-if="isAppUpdateAvailable()" class="bg-warning text-white d-flex justify-content-center align-items-center p-1">
      <small class="me-3">{{ getHelper().trans('labels.app_update_available') }}</small>
      <button type="button" @click.prevent="reloadPage()" class="btn btn-sm btn-outline-light">{{ getHelper().trans('labels.update') }}</button>
    </div>
    <Menu v-if="this.isLoggedIn"></Menu>
    <div class="pl-2 pr-2" v-if="inited">
      <router-view/>
    </div>
    <notifications class="m-3"/>
    <b-modal centered :title="getHelper().trans('labels.2fa_required')"
             modal-class="modal-2fa"
             body-class="p-0 m-0"
             :modelValue="twoFaCodeModalConfig !== null"
             footer-class="d-flex justify-content-end"
             @update:modelValue="$event === false && (twoFaCodeModalConfig = null)">
      <template #default>
        <loaderWrapper ref="loaderTwoFaCode">
        <div class="d-flex justify-content-center flex-row p-3">
          <FormInputWrapper v-slot="parentProps" :errors="getHelper().getErrors('2fa', 'X-2FA-CODE')" >
            <input type="text"
                   v-maska
                   data-maska="999 999"
                   data-maska-tokens="9:[0-9]"
                   :class="parentProps.class" class="form-control" v-model="twoFaCode" :placeholder="getHelper().trans('labels.2fa_enter_code')">
          </FormInputWrapper>
          <div>
            <button type="button" @click.prevent="confirm2faCode()" class="btn btn-primary text-capitalize-first ms-2">
              {{ getHelper().trans('labels.2fa_verify') }}
            </button>
          </div>
        </div>
        </loaderWrapper>
      </template>
      <template #footer>
        <button type="button" @click.prevent="cancel2faCode()" class="btn btn-outline-secondary btn-sm text-capitalize-first mt-2">
          {{ getHelper().trans('button.cancel') }}
        </button>


      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from 'axios';
import Menu from './components/Menu';
import {BModal} from "bootstrap-vue-3";
import FormInputWrapper from "@/components/FormInputWrapper.vue";
import {helper} from "@/service/CommonHelper";
import LoaderWrapper from "@/components/LoaderWrapper.vue";
import APIClient, {api} from "@/service/APIClient";

export default {
  name: "App",
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'getAuth',
      'getUser',
      'getAppData',
    ]),
  },
  components: {
    LoaderWrapper,
    FormInputWrapper,
    BModal,
    Menu,
  },
  data() {
    return {
      intervalUpdate: null,
      intervalUserUpdate: null,
      inited: false,
      currentAppVersion: null,
      serverAppVersion: null,
      promise2fa: new Promise((resolve, reject) => {}),
      twoFaCodeModalConfig: null,
      twoFaCode: '',
      resolve2fa: null,
      reject2fa: null
    }
  },
  methods: {
    reloadPage: function () {
      document.location.reload()
    },
    getHelper: function () {
        return helper
    },
    isAppUpdateAvailable: function () {
      if (this.currentAppVersion === null) {
        return false;
      }

      if (this.serverAppVersion === null) {
        return false
      }

      return this.currentAppVersion < this.serverAppVersion;
    },
    cancel2faCode: async function () {
      this.reject2fa && this.reject2fa()
      this.twoFaCodeModalConfig = null
    },
    confirm2faCode: async function () {
      try {
        this.$refs.loaderTwoFaCode?.show();
        this.twoFaCodeModalConfig.headers['x-2fa-code'] = this.twoFaCode.replace(" ", "")
        const resp = await axios.request(this.twoFaCodeModalConfig);
        this.resolve2fa(resp)
        this.twoFaCodeModalConfig = null
      } catch (e) {
        helper.handleErrors('2fa', e)
        const errors = helper.getErrors('2fa', 'X-2FA-CODE');
        if (!errors || errors.length === 0) {
          this.reject2fa(e)
          this.twoFaCodeModalConfig = null
        }
      } finally {
        this.$refs.loaderTwoFaCode?.hide();
      }
    },
    setupAxiosDefaults: function () {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.getAuth.token
      axios.defaults.headers.common['X-GA-ID'] = this.getAppData?.ga_id
    },
    resolveApiUrl: async function () {
      for (let i = 0; i <= 3; i++) {
        const suffix = i === 0 ? '' : i.toString();
        axios.defaults.baseURL = window.API_URL.replace('api.', `api${suffix}.`);
        try {
          const resp = await api.ping();
          if (resp.time) {
            console.log('api url: ' + axios.defaults.baseURL)
            return;
          }
        } catch (e) {
        }
        console.log('api url failed')
        axios.defaults.baseURL = window.API_URL;
      }
    },
    setupAxios: function () {
      let self = this;
      let auth = {
        isRefreshing: false,
        refreshingCall: undefined,
      }

      function refreshToken(cb) {
        if (auth.isRefreshing) {
          return auth.refreshingCall.then(cb);
        }

        auth.isRefreshing = true;

        const refreshToken = self.getAuth.refreshToken;
        auth.refreshingCall = axios.post('/token/refresh', {refreshToken: refreshToken}, {headers: {Authorization: ""}})
          .then(tokenRefreshResponse => {
            self.$store.commit('authenticate', tokenRefreshResponse.data);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token
            axios.defaults.headers.common['X-GA-ID'] = self.getAppData?.ga_id
            auth.isRefreshing = false;
            auth.refreshingCall = undefined;
            return Promise.resolve(tokenRefreshResponse.data.token);
          }).then(cb);
        return auth.refreshingCall;
      }

      axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const request = error.config;
          if (
            request &&
            request.url === '/token/refresh' &&
            request.method === 'post' &&
            error &&
            error.response &&
            error.response.status === 401
          ) {
            self.$store.commit('removeAuth');
            window.location.reload();
            return;
          }

          if (error && error.response && error.response.status === 401) {
            if (error.response.headers['x-2fa-code'] === 'required') {
              this.twoFaCodeModalConfig = error.config;
              this.twoFaCode = '';
              helper.clearErrors('2fa')
              return new Promise((resolve, reject) => {
                this.resolve2fa = resolve
                this.reject2fa = reject
              });
            }

            return refreshToken(() => {
              error.config.headers['Authorization'] = 'Bearer ' + this.getAuth.token
              error.config.headers['X-GA-ID'] = this.getAppData?.ga_id
              return axios.request(error.config);
            });
          }

          throw error;
        }
      );


    }
  },
  async created() {
    axios.defaults.headers.common['X-GA-REF'] =  window.X_GA_REF
    await this.resolveApiUrl();
    this.setupAxios();
    if (this.isLoggedIn) {
      this.setupAxiosDefaults();

      try {
        await this.$store.dispatch('loadAppData');
        this.serverAppVersion =  this.getAppData?.version
        this.currentAppVersion = this.serverAppVersion;
        axios.defaults.headers.common['X-GA-ID'] = this.getAppData?.ga_id
      } catch (e) {

      }

      this.intervalUpdate = setInterval(async () => {
        try {
          await this.$store.dispatch('loadAppData');
          this.serverAppVersion = this.getAppData?.version
          axios.defaults.headers.common['X-GA-ID'] = this.getAppData?.ga_id
        } catch (e) {
        }
      }, 60000)

      this.intervalUserUpdate = setInterval(async () => {
        await this.$store.dispatch('loadUser');
      }, 62000)

      await this.$store.dispatch('loadUser');

    }
    this.inited = true
  },
  watch: {
    async isLoggedIn(newValue, oldValue) {
      if (newValue) {
        this.setupAxiosDefaults();
        try {
          this.serverAppVersion =  this.getAppData?.version
          this.currentAppVersion = this.serverAppVersion;
          axios.defaults.headers.common['X-GA-ID'] = this.getAppData?.ga_id
        } catch (e) {

        }

        this.intervalUpdate = setInterval(async () => {
          try {
            await this.$store.dispatch('loadAppData');
            this.serverAppVersion = this.getAppData?.version
            axios.defaults.headers.common['X-GA-ID'] = this.getAppData?.ga_id
          } catch (e) {
          }
        }, 60000)

        this.intervalUserUpdate = setInterval(async () => {
          await this.$store.dispatch('loadUser');
        }, 62000)
      } else {
        if (this.intervalUpdate) {
          clearInterval(this.intervalUpdate)
          this.intervalUpdate = null;
        }
        if (this.intervalUserUpdate) {
          clearInterval(this.intervalUserUpdate)
          this.intervalUserUpdate = null;
        }
      }
    }
  }
}
</script>

