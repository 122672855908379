import moment from 'moment-timezone';

class DateHelper {

  public getUserTimeZone(): string {
    return window.userTimeZone ?? 'UTC';
  }
  public format(date: string | null, format: string | null = null, defaultValue: string = '', timezone: string|null = null) : string {
    if (date === null) {
      return defaultValue;
    }

    return (moment(date)).utcOffset(0).tz(timezone ?? (this.getUserTimeZone())).format(format ?? 'YYYY-MM-DD HH:mm');
  }

  toUTC(date: string|null, format: string | null = null, fromTimezone: string|null = null): string|null {
    if (!date) {
      return null;
    }

    return moment.tz(date, fromTimezone ?? (this.getUserTimeZone())).tz('UTC').format(format ?? 'YYYY-MM-DD HH:mm:ss');
  }
}

export const dateHelper = new DateHelper();
