import axios from "axios";
import TraderDTO from "@/models/TraderDTO";
import AccessTokenDTO from "@/models/AccessTokenDTO";
import AccountDTO from "@/models/AccountDTO";
import AccountOperationDTO from "@/models/AccountOperationDTO";
import PaginationDTO from "@/models/PaginationDTO";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import qs from 'qs';
import TraderDealDTO from "@/models/TraderDealDTO";
import DealRequestDTO from "@/models/request/DealRequestDTO";
import RequisitesRequestDTO from "@/models/request/RequisitesRequestDTO";
import TraderRequisitesDTO from "@/models/TraderRequisitesDTO";
import PaymentMethodsDTO from "@/models/PaymentMethodsDTO";
import RequisitesUpdateRequestDTO from "@/models/request/RequisitesUpdateRequestDTO";
import UserDTO from "@/models/UserDTO";
import ExtendedDealDTO from "@/models/ExtendedDealDTO";
import MerchantDTO from "@/models/MerchantDTO";
import InvoiceDTO from "@/models/InvoiceDTO";
import InvoiceDealsRequestDTO from "@/models/request/InvoiceDealsRequestDTO";
import InvoiceDealDTO from "@/models/InvoiceDealDTO";
import TraderDeviceDTO from "@/models/TraderDeviceDTO";
import MerchantStoreDTO from "@/models/MerchantStoreDTO";
import MerchantStoreUpdateRequestDTO from "@/models/request/MerchantStoreUpdateRequestDTO";
import WalletDTO from "@/models/WalletDTO";
import TraderAccountDTO from "@/models/TraderAccountDTO";
import MerchantStoreAccountDTO from "@/models/MerchantStoreAccountDTO";
import TradersUpdateRequestDTO from "@/models/request/TradersUpdateRequestDTO";
import TradersCreateRequestDTO from "@/models/request/TradersCreateRequestDTO";
import UserUpdateRequestDTO from "@/models/request/UserUpdateRequestDTO";
import UserCreateRequestDTO from "@/models/request/UserCreateRequestDTO";
import MerchantUpdateRequestDTO from "@/models/request/MerchantUpdateRequestDTO";
import MerchantCreateRequestDTO from "@/models/request/MerchantCreateRequestDTO";
import AccountWithdrawalRequestDTO from "@/models/request/AccountWithdrawalRequestDTO";
import WithdrawalDTO from "@/models/WithdrawalDTO";
import InviteRequestDTO from "@/models/InviteRequestDTO";
import InviteRequestUserRequestDTO from "@/models/request/InviteRequestUserRequestDTO";
import TraderRequisiteGroupDTO from "@/models/TraderRequisiteGroupDTO";
import TelegramCreateInviteResponseDTO from "@/models/TelegramCreateInviteResponseDTO";
import AccountTransferRequestDTO from "@/models/request/AccountTransferRequestDTO";
import TelegramMeDTO from "@/models/TelegramMeDTO";
import TraderDealsStatsDTO from "@/models/TraderDealsStatsDTO";
import AttachmentDTO from "@/models/AttachmentDTO";
import TraderPayoutMethodDTO from "@/models/TraderPayoutMethodDTO";
import PayoutMethodUpdateRequestDTO from "@/models/request/PayoutMethodUpdateRequestDTO";
import TwoFactorAuthEnableResponseDTO from "@/models/TwoFactorAuthEnableResponseDTO";
import PaymentOptionsDTO from "@/models/PaymentOptionsDTO";
import UserAccountDTO from "@/models/UserAccountDTO";
import SummaryStatsDTO from "@/models/SummaryStatsDTO";
import {Direction} from "@/models/enum/Direction";
import DealHistoryDTO from "@/models/DealHistoryDTO";
import TraderRewardSettingsDTO from "@/models/TraderRewardSettingsDTO";
import AntiFraudAlertDTO from "@/models/AntiFraudAlertDTO";
import {DealStatus} from "@/models/enum/DealStatus";
import FrontendAppDTO from "@/models/FrontendAppDTO";
import MerchantOperatorDTO from "@/models/MerchantOperatorDTO";
import MerchantOperatorCreateRequestDTO from "@/models/request/MerchantOperatorCreateRequestDTO";
import MerchantOperatorUpdateRequestDTO from "@/models/request/MerchantOperatorUpdateRequestDTO";
import WithdrawalWhitelistDTO from "@/models/WithdrawalWhitelistDTO";
import MoneyDTO from "@/models/MoneyDTO";
import {trafficGroups} from "@/service/api/trafficGroups";
import {internalTransferRequests} from "@/service/api/internalTransferRequests";
import {teamLeadRewardSettings} from "@/service/api/teamLeadRewardSettings";
import {traderRewardSettings} from "@/service/api/traderRewardSettings";
import {deposits} from "@/service/api/deposits";
import {traderManualPriorities} from "@/service/api/traderManualPriorities";
import {statistic} from "@/service/api/statistic";
import {BlockReason} from "@/models/enum/BlockReason";
import TraderPayoutSettingsDTO from "@/models/TraderPayoutSettingsDTO";
import OrderByDTO from "@/models/OrderByDTO";
import {userPermissions} from "@/service/api/userPermissions";
import AutoWithdrawalUpdateRequestDTO from "@/models/request/AutoWithdrawalUpdateRequestDTO";
import {additionalRewardSettings} from "@/service/api/additionalRewardSettings";
import SystemSettingsDTO from "@/models/SystemSettingsDTO";
import SystemSettingsUpdateRequestDTO from "@/models/request/SystemSettingsUpdateRequestDTO";
import {DevicesStatus} from "@/models/enum/DevicesStatus";
import TraderDeviceStatusDTO from "@/models/TraderDeviceStatusDTO";

export default class APIClient {

    baseUrl = () => {
        return axios.defaults.baseURL;
    }

    call =  {
        userPermissions,
        trafficGroups,
        internalTransferRequests,
        teamLeadRewardSettings,
        traderRewardSettings,
        deposits,
        traderManualPriorities,
        additionalRewardSettings,
        statistic
    }

    me = async (): Promise<UserDTO|TraderDTO|MerchantDTO|MerchantOperatorDTO> => {
        let resp = await axios({
            url: `/users/me`,
            method: 'GET',
        });

        return resp.data;
    }

    ping = async (): Promise<any> => {
        let resp = await axios({
            url: `/ping`,
            method: 'GET',
        });

        return resp.data;
    }

    getInviteRequest = async (token: string): Promise<InviteRequestDTO> => {
        let resp = await axios({
            url: `/invite-requests/${token}`,
            method: 'GET',
        });

        return resp.data;
    }

    useInviteRequest = async (token: string, req: InviteRequestUserRequestDTO): Promise<AccessTokenDTO> => {
        let resp = await axios({
            url: `/invite-requests/${token}/use`,
            data: {
                ...req
            },
            method: 'POST',
        });

        return resp.data;
    }

    changeMeStatus = async (isActive: boolean, direction: Direction): Promise<any> => {
        return axios({
            url: `/traders/me/change-status`,
            method: 'PUT',
            data: {
                isActive,
                direction
            },
        });
    }

    changeTraderStatus = async (id: number, isActive: boolean, direction: Direction): Promise<TraderDTO> => {
        return (await axios({
            url: `/traders/${id}/change-status`,
            method: 'PUT',
            data: {
                isActive,
                direction
            },
        })).data as TraderDTO;
    }

    blockTrader = async (id: number, isBlocked: boolean, blockReason: BlockReason|null): Promise<TraderDTO> => {
        return (await axios({
            url: `/traders/${id}/toggle-block`,
            method: 'PUT',
            data: {
                isBlocked,
                blockReason
            },
        })).data as TraderDTO;
    }

    trader2faDisable = async (id: number): Promise<void> => {
        (await axios({
            url: `/traders/${id}/2fa/disable`,
            method: 'PUT',
        })).data
    }


    traderRewardSettings = async (): Promise<TraderRewardSettingsDTO[]> => {
        return (await axios({
            url: `/traders/me/traffic-groups/reward-settings`,
            method: 'GET',
        })).data as TraderRewardSettingsDTO[];
    }

    traderPayoutSettings = async (): Promise<TraderPayoutSettingsDTO> => {
        return (await axios({
            url: `/trader-payout-settings`,
            method: 'GET',
        })).data as TraderPayoutSettingsDTO;
    }

    traderPayoutSettingsUpdate = async (item: TraderPayoutSettingsDTO): Promise<TraderPayoutSettingsDTO> => {
        return (await axios({
            url: `/trader-payout-settings`,
            data: {
                ...item,
                minAmount: item.minAmount ? item.minAmount : null,
                maxAmount: item.maxAmount ? item.maxAmount : null,
            },
            method: 'PUT',
        })).data as TraderPayoutSettingsDTO;
    }

    updateProfile = async (profile: any): Promise<any> => {
        return axios({
            url: `/users/profile`,
            method: 'PUT',
            data: {
                ...profile
            },
        });
    }

    devicesDownload = async (id: string): Promise<any> => {
        let resp = await axios({
            url: `/trader-devices/${id}/macrodroid`,
            method: 'GET',
        });

        return resp.data;
    }

    devicesRegisterDevice = async (id: string, version: string): Promise<any> => {
        let resp = await axios({
            url: `/trader-devices/${id}/register-device?version=${version}`,
            method: 'GET',
        });

        return resp.data;
    }

    deviceAppDownload = async (id: any): Promise<any> => {
        let resp = await axios({
            url: `/trader-devices/android-apps/${id}/download`,
            method: 'GET',
            responseType: 'blob',
        });

        return resp.data;
    }

    bankAppsLink = async (): Promise<string> => {
        let resp = await axios({
            url: `/trader-devices/bank-apps-external-link`,
            method: 'GET',
        });

        return resp.data as string;
    }

    devicesListApps = async (): Promise<any> => {
        let resp = await axios({
            url: `/trader-devices/android-apps`,
            method: 'GET',
        });

        return resp.data;
    }

    createTgInviteRequest = async (): Promise<TelegramCreateInviteResponseDTO> => {
        let resp = await axios({
            url: `/telegram/invite-requests`,
            method: 'POST',
        });

        return resp.data as TelegramCreateInviteResponseDTO;
    }

    getTgMe = async (): Promise<TelegramMeDTO> => {
        let resp = await axios({
            url: `/telegram/me`,
            method: 'GET',
        });

        return resp.data as TelegramMeDTO;
    }

    twoFactorAuthDisable = async (): Promise<void> => {
        await axios({
            url: `/2fa/disable`,
            method: 'POST',
        });
    }

    twoFactorAuthEnable = async (): Promise<TwoFactorAuthEnableResponseDTO> => {
        let resp = await axios({
            url: `/2fa/enable`,
            method: 'POST',
        });

        return resp.data as TwoFactorAuthEnableResponseDTO;
    }

    twoFactorAuthEnableConfirm = async (data: TwoFactorAuthEnableResponseDTO): Promise<void> => {
        await axios({
            url: `/2fa/enable-confirm`,
            method: 'POST',
            data
        });
    }

    deleteTgMe = async (): Promise<TelegramMeDTO> => {
        let resp = await axios({
            url: `/telegram/me`,
            method: 'DELETE',
        });

        return resp.data as TelegramMeDTO;
    }

    updateTgNotifications = async (requestDTO: any): Promise<TelegramMeDTO> => {
        let resp = await axios({
            url: `/telegram/me/notifications`,
            method: 'PUT',
            data: {
                ...requestDTO
            }
        });

        return resp.data as TelegramMeDTO;
    }

    devicesMessageLogs = async (id: string, params: Object): Promise<PaginatedResponseDTO<any>> => {
        let resp = await axios({
            url: `/trader-devices/${id}/message-log`,
            method: 'GET',
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data;
    }

    storeGet = async (id: number): Promise<MerchantStoreDTO> => {
        let resp = await axios({
            url: `/stores/${id}`,
            method: 'GET',
        });

        return resp.data as MerchantStoreDTO;
    }


    storesAll = async (): Promise<MerchantStoreDTO[]> => {
        let resp = await axios({
            url: `/stores/all`,
            method: 'GET',
        });

        return resp.data as MerchantStoreDTO[];
    }

    updateStoreApiKey = async (id: number): Promise<MerchantStoreDTO> => {
        let resp = await axios({
            url: `/stores/${id}/api-key`,
            method: 'PUT',
        })
        return resp.data as MerchantStoreDTO
    }

    deleteStore = async (id: number): Promise<void> => {
        let resp = await axios({
            url: `/stores/${id}`,
            method: 'DELETE',
        })
    }

    updateStore = async (id: number, requestDTO: MerchantStoreUpdateRequestDTO): Promise<MerchantStoreDTO> => {
        let resp = await axios({
            url: `/stores/${id}`,
            data: requestDTO,
            method: 'PUT',
        })
        return resp.data as MerchantStoreDTO
    }

    createStore = async (requestDTO: MerchantStoreUpdateRequestDTO): Promise<MerchantStoreDTO> => {
        let resp = await axios({
            url: `/stores`,
            data: requestDTO,
            method: 'POST',
        })
        return resp.data as MerchantStoreDTO
    }


    devicesAll = async (): Promise<TraderDeviceDTO[]> => {
        let resp = await axios({
            url: `/trader-devices/all`,
            method: 'GET',
        });

        return resp.data as TraderDeviceDTO[];
    }

    traderRequisiteGroupsAll = async (): Promise<TraderRequisiteGroupDTO[]> => {
        let resp = await axios({
            url: `/trader-requisite-groups/all`,
            method: 'GET',
        });

        return resp.data as TraderRequisiteGroupDTO[];
    }

    traderRequisiteGroups = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<TraderRequisiteGroupDTO>> => {
        let resp = await axios({
            url: `/trader-requisite-groups`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            }
        });

        return resp.data as PaginatedResponseDTO<TraderRequisiteGroupDTO>;
    }

    traderDevices = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<TraderDeviceDTO>> => {
        let resp = await axios({
            url: `/trader-devices`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            }
        });

        return resp.data as PaginatedResponseDTO<TraderDeviceDTO>;
    }

    devicesCreate = async (device: TraderDeviceDTO): Promise<TraderDeviceDTO> => {
        let resp = await axios({
            url: `/trader-devices`,
            data: device,
            method: 'POST',
        });

        return resp.data as TraderDeviceDTO
    }

    devicesGet = async (id: string): Promise<TraderDeviceDTO> => {
        let resp = await axios({
            url: `/trader-devices/${id}`,
            method: 'GET',
        });

        return resp.data as TraderDeviceDTO
    }

    devicesUpdate = async (id: string, device: TraderDeviceDTO): Promise<TraderDeviceDTO> => {
        let resp = await axios({
            url: `/trader-devices/` + id,
            data: device,
            method: 'PUT',
        });

        return resp.data as TraderDeviceDTO
    }

    devicesDelete = async (id: string): Promise<void> => {
        let resp = await axios({
            url: `/trader-devices/` + id,
            method: 'DELETE',
        });
    }

    devicesChangeStatus = async (id: string, status: TraderDeviceStatusDTO): Promise<void> => {
        let resp = await axios({
            url: `/trader-devices/` + id + `/change-status`,
            data: status,
            method: 'PUT',
        });
    }

    traderRequisiteGroupDelete = async (id: number): Promise<void> => {
        let resp = await axios({
            url: `/trader-requisite-groups/` + id,
            method: 'DELETE',
        });
    }

    traderRequisiteGroupToggleRequisites = async (id: number, mode: string): Promise<TraderRequisiteGroupDTO> => {
        let resp = await axios({
            url: `/trader-requisite-groups/${id}/requisites/${mode}`,
            method: 'POST',
        });

        return resp.data as TraderRequisiteGroupDTO
    }

    traderRequisiteGroupUpdate = async (id: number, group: TraderRequisiteGroupDTO): Promise<TraderRequisiteGroupDTO> => {
        let resp = await axios({
            url: `/trader-requisite-groups/` + id,
            data: group,
            method: 'PUT',
        });

        return resp.data as TraderRequisiteGroupDTO
    }

    traderRequisiteGroupCreate = async (device: TraderRequisiteGroupDTO): Promise<TraderRequisiteGroupDTO> => {
        let resp = await axios({
            url: `/trader-requisite-groups`,
            data: device,
            method: 'POST',
        });

        return resp.data as TraderRequisiteGroupDTO
    }

    accountsAll = async (): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/accounts/all`,
            method: 'GET',
        });

        return resp.data as AccountDTO[];
    }

    accountsGet = async (id: string): Promise<AccountDTO> => {
        let resp = await axios({
            url: `/accounts/${id}`,
            method: 'GET',
        });

        return resp.data as AccountDTO;
    }

    traderAccountsSummary = async (): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/trader-accounts/summary`,
            method: 'GET',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as AccountDTO[];
    }

    merchantStoreAccountsSummary = async (): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/merchant-store-accounts/summary`,
            method: 'GET',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as AccountDTO[];
    }

    systemAccountsSummary = async (): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/system-accounts/summary`,
            method: 'GET',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as AccountDTO[];
    }

    userAccountsSummary = async (req: Object = {}): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/user-accounts/summary`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                ...req
            }
        });

        return resp.data as AccountDTO[];
    }

    traderAccounts = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<TraderAccountDTO>> => {
        let resp = await axios({
            url: `/trader-accounts`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            }
        });

        return resp.data as PaginatedResponseDTO<TraderAccountDTO>;
    }

    userAccounts = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<UserAccountDTO>> => {
        let resp = await axios({
            url: `/user-accounts`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            }
        });

        return resp.data as PaginatedResponseDTO<UserAccountDTO>;
    }

    traders = async (pagination: PaginationDTO, req: Object = {}, orderByDTO: OrderByDTO = {field: "id", direction: "asc"}): Promise<PaginatedResponseDTO<TraderDTO>> => {
        let orderBy = {} as any
        if (orderByDTO.field) {
            orderBy[orderByDTO.field] = orderByDTO.direction
        }
        let resp = await axios({
            url: `/traders`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...req,
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TraderDTO>;
    }

    merchants = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<MerchantDTO>> => {
        let resp = await axios({
            url: `/merchants`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            }
        });

        return resp.data as PaginatedResponseDTO<MerchantDTO>;
    }

    merchantStores = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<MerchantStoreDTO>> => {
        let resp = await axios({
            url: `/stores`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<MerchantStoreDTO>;
    }


    withdrawalWhitelistDelete = async (id: number): Promise<WithdrawalWhitelistDTO> => {
        let resp = await axios({
            url: `/withdrawal-whitelists/${id}`,
            method: 'DELETE'
        });

        return resp.data as WithdrawalWhitelistDTO;
    }

    withdrawalWhitelistCreate = async (item: WithdrawalWhitelistDTO): Promise<WithdrawalWhitelistDTO> => {
        let resp = await axios({
            url: `/withdrawal-whitelists`,
            method: 'POST',
            data: {
                ...item
            }
        });

        return resp.data as WithdrawalWhitelistDTO;
    }

    withdrawalWhitelist = async (pagination: PaginationDTO, req: any = {}): Promise<PaginatedResponseDTO<WithdrawalWhitelistDTO>> => {
        let resp = await axios({
            url: `/withdrawal-whitelists`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<WithdrawalWhitelistDTO>;
    }

    merchantsStoresAll = async (merchantId: number): Promise<MerchantStoreDTO[]> => {
        let resp = await axios({
            url: `/merchants/${merchantId}/stores/all`,
            method: 'GET',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as MerchantStoreDTO[];
    }

    users = async (pagination: PaginationDTO, request: any): Promise<PaginatedResponseDTO<UserDTO>> => {
        let resp = await axios({
            url: `/users`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<UserDTO>;
    }

    merchantOperators = async (pagination: PaginationDTO, request: any): Promise<PaginatedResponseDTO<MerchantOperatorDTO>> => {
        let resp = await axios({
            url: `/merchant-operators`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<MerchantOperatorDTO>;
    }

    trEnergyAccount = async (): Promise<any> => {
        let resp = await axios({
            url: `/tr-energy`,
            method: 'GET',
        });

        return resp.data as any;
    }

    feeeioData = async (): Promise<any> => {
        let resp = await axios({
            url: `/feee-io`,
            method: 'GET',
        });

        return resp.data as any;
    }

    antiFraudAlertsReviewed = async (id: number): Promise<void> => {
        await axios({
            url: `/anti-fraud-alerts/${id}/reviewed`,
            method: 'PUT',
        });
    }

    antiFraudAlerts = async (pagination: PaginationDTO, requestDTO: any): Promise<PaginatedResponseDTO<AntiFraudAlertDTO>> => {
        let resp = await axios({
            url: `/anti-fraud-alerts`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...requestDTO
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<AntiFraudAlertDTO>;
    }

    antiFraudAlertsCount = async (lastId: number|null): Promise<boolean> => {
        let resp = await axios({
            url: `/anti-fraud-alerts/count`,
            method: 'GET',
            params: {
                lastId
            }
        });

        return resp.data as boolean;
    }

    wallets = async (pagination: PaginationDTO, req: any = {}): Promise<PaginatedResponseDTO<WalletDTO>> => {
        let resp = await axios({
            url: `/wallets`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<WalletDTO>;
    }

    walletsSystemAll = async (): Promise<WalletDTO[]> => {
        let resp = await axios({
            url: `/wallets/system/all`,
            method: 'GET',
        });

        return resp.data as WalletDTO[];
    }

    merchantStoreAccounts = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<MerchantStoreAccountDTO>> => {
        let resp = await axios({
            url: `/merchant-store-accounts`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<MerchantStoreAccountDTO>;
    }

    merchantStoreAccountsAutoWithdrawal = async (id: string, requestDTO: AutoWithdrawalUpdateRequestDTO): Promise<MerchantStoreAccountDTO> => {
        let resp = await axios({
            url: `/merchant-store-accounts/${id}/auto-withdrawal`,
            method: 'POST',
            data: {
               ...requestDTO
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as MerchantStoreAccountDTO;
    }

    invoiceDeal = async (id: string): Promise<InvoiceDealDTO> => {
        let resp = await axios({
            url: `/invoice-deals/${id}`,
            method: 'GET',
        });

        return resp.data as InvoiceDealDTO;
    }

    withdrawal = async (id: string): Promise<WithdrawalDTO> => {
        let resp = await axios({
            url: `/withdrawals/${id}`,
            method: 'GET',
        });

        return resp.data as WithdrawalDTO;
    }

    processWithdrawal = async (id: string, request: Object): Promise<WithdrawalDTO> => {
        let resp = await axios({
            url: `/withdrawals/${id}/process`,
            data: {
                ...request
            },
            method: 'POST',
        });

        return resp.data as WithdrawalDTO;
    }

    cancelWithdrawal = async (id: string): Promise<WithdrawalDTO> => {
        let resp = await axios({
            url: `/withdrawals/${id}/cancel`,
            method: 'POST',
        });

        return resp.data as WithdrawalDTO;
    }

    renewWithdrawal = async (id: string): Promise<WithdrawalDTO> => {
        let resp = await axios({
            url: `/withdrawals/${id}/renew`,
            method: 'POST',
        });

        return resp.data as WithdrawalDTO;
    }

    accountWallet = async (id: string): Promise<WalletDTO> => {
        let resp = await axios({
            url: `/accounts/${id}/wallet`,
            method: 'GET',
        });

        return resp.data as WalletDTO;
    }

    accountWithdrawalWhitelistToggle = async (id: string, action: string): Promise<AccountDTO> => {
        let resp = await axios({
            url: `/accounts/${id}/withdrawal-whitelists/${action}`,
            method: 'POST',
        });

        return resp.data as AccountDTO;
    }

    invoiceDeals = async (pagination: PaginationDTO, orderBy: Object, request: InvoiceDealsRequestDTO): Promise<PaginatedResponseDTO<InvoiceDealDTO>> => {
        let resp = await axios({
            url: `/invoice-deals`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<InvoiceDealDTO>;
    }

    dealsStats = async (request: DealRequestDTO): Promise<TraderDealsStatsDTO[]> => {
        let resp = await axios({
            url: `/trader-deals/stats`,
            method: 'GET',
            params: {
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as TraderDealsStatsDTO[];
    }

    export = async (name: string, request: Object): Promise<any> => {
        let resp = await axios({
            url: `/export/${name}`,
            method: 'GET',
            responseType: 'blob',
            params: {
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data;
    }

    statisticSummary = async (request: Object): Promise<SummaryStatsDTO[]> => {
        let resp = await axios({
            url: `/statistic/summary`,
            method: 'GET',
            params: {
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as SummaryStatsDTO[];
    }

    statisticOnlineSummaryAmounts = async (request: Object): Promise<any> => {
        let resp = await axios({
            url: `/statistic/online-summary/amounts`,
            method: 'GET',
            params: {
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as any;
    }

    statisticOnlineSummary = async (request: Object): Promise<any> => {
        let resp = await axios({
            url: `/statistic/online-summary`,
            method: 'GET',
            params: {
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as any;
    }

    deals = async (pagination: PaginationDTO, orderByDTO: OrderByDTO = {field: 'createdAt', direction: 'desc'} as OrderByDTO, request: DealRequestDTO): Promise<PaginatedResponseDTO<ExtendedDealDTO>> => {
        let orderBy = {} as any
        if (orderByDTO.field) {
            orderBy[orderByDTO.field] = orderByDTO.direction
        }
        let resp = await axios({
            url: `/trader-deals`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<ExtendedDealDTO>;
    }

    dealHistory = async (dealId: string, pagination: PaginationDTO): Promise<PaginatedResponseDTO<DealHistoryDTO>> => {
        let resp = await axios({
            url: `/trader-deals/${dealId}/history`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
            },
        });

        return resp.data as PaginatedResponseDTO<DealHistoryDTO>;
    }

    deal = async (id: string): Promise<TraderDealDTO> => {
        let resp = await axios({
            url: `/trader-deals/${id}`,
            method: 'GET',
        });

        return resp.data as TraderDealDTO;
    }

    dealSendWebhook = async (id: string, status: DealStatus): Promise<void> => {
        let resp = await axios({
            url: `/trader-deals/${id}/webhook/${status}`,
            method: 'POST',
        });
    }

    dealAssignTrader = async (id: string, traderId: number): Promise<void> => {
        let resp = await axios({
            url: `/trader-deals/${id}/assign`,
            method: 'PUT',
            data: {
                traderId: traderId
            }
        });
    }

    payOutSupportedMethodsSources = async (): Promise<string[]> => {
        let resp = await axios({
            url: `/trader-deals/pay-out/supported-methods-sources`,
            method: 'GET',
        });

        return resp.data as string[];
    }

    attachmentsUpload = async (file: File): Promise<AttachmentDTO> => {
        const formData = new FormData();
        formData.append('file', file)

        let resp = await axios({
            url: `/attachments`,
            data: formData,
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return resp.data as AttachmentDTO;
    }

    receiptVerification = async (file: File): Promise<any> => {
        const formData = new FormData();
        formData.append('file', file)

        let resp = await axios({
            url: `/receipt-verification`,
            data: formData,
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return resp.data as any;
    }

    dealChangeStatus = async (id: string, action: string, req: Object = {}): Promise<TraderDealDTO> => {
        let resp = await axios({
            url: `/trader-deals/${id}/${action}`,
            data: {
                ...req
            },
            method: 'PUT',
        });

        return resp.data as TraderDealDTO;
    }

    dealDisputeAutocompleting = async (id: string, action: string, form: any = {}): Promise<TraderDealDTO> => {
        let resp = await axios({
            url: `/trader-deals/${id}/dispute-autocompleting/${action}`,
            method: 'PUT',
            data: form
        });

        return resp.data as TraderDealDTO;
    }

    dealsCount = async (request: DealRequestDTO): Promise<number> => {
        let resp = await axios({
            url: `/trader-deals/count`,
            method: 'GET',
            params: {
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as number;
    }

    paymentMethods = async (): Promise<PaymentMethodsDTO[]> =>  {
        let resp = await axios({
            url: `/payment-methods`,
            method: 'GET',
        })

        return resp.data as PaymentMethodsDTO[];
    }

    rateSources = async (): Promise<string[]> =>  {
        let resp = await axios({
            url: `/rate-sources`,
            method: 'GET',
        })

        return resp.data as string[];
    }

    app = async (): Promise<any> =>  {
        let resp = await axios({
            url: `/app`,
            method: 'GET',
        })

        return resp.data as any;
    }

    appsAll = async (): Promise<FrontendAppDTO[]> =>  {
        let resp = await axios({
            url: `/app/all`,
            method: 'GET',
        })

        return resp.data as FrontendAppDTO[];
    }

    paymentOptions = async (): Promise<PaymentOptionsDTO[]> =>  {
        let resp = await axios({
            url: `/payment-methods/options`,
            method: 'GET',
        })

        return resp.data as PaymentOptionsDTO[];
    }

    crossBorderCurrencies = async (): Promise<string[]> =>  {
        let resp = await axios({
            url: `/payment-methods/cross-border-currencies`,
            method: 'GET',
        })

        return resp.data as string[];
    }

    payoutMethods = async (pagination: PaginationDTO, orderBy: Object): Promise<PaginatedResponseDTO<TraderPayoutMethodDTO>> => {
        let resp = await axios({
            url: `/payout-methods`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TraderRequisitesDTO>;
    }

    requisites = async (pagination: PaginationDTO, orderBy: Object, request: RequisitesRequestDTO): Promise<PaginatedResponseDTO<TraderRequisitesDTO>> => {
        let resp = await axios({
            url: `/requisites`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TraderRequisitesDTO>;
    }

    withdrawals = async (pagination: PaginationDTO, requestDTO: any = {}): Promise<PaginatedResponseDTO<WithdrawalDTO>> => {
        let resp = await axios({
            url: `/withdrawals`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...requestDTO
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<WithdrawalDTO>;
    }

    withdrawalsFee= async (): Promise<MoneyDTO> => {
        let resp = await axios({
            url: `/withdrawals/fee`,
            method: 'GET',
        });

        return resp.data as MoneyDTO;
    }

    accountOperations = async (pagination: PaginationDTO, orderBy: Object, request: Object): Promise<PaginatedResponseDTO<AccountOperationDTO>> => {
        let resp = await axios({
            url: `/account-operations`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request

            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<AccountOperationDTO>;
    }

    invoices = async (pagination: PaginationDTO, orderBy: Object, request: Object = {}): Promise<PaginatedResponseDTO<InvoiceDTO>> => {
        let resp = await axios({
            url: `/invoices`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<InvoiceDTO>;
    }

    updateRequisites = async (id: number, requestDTO: RequisitesUpdateRequestDTO): Promise<TraderRequisitesDTO> => {
        let resp = await axios({
            url: `/requisites/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as TraderRequisitesDTO
    }

    updateRequisitesPayInDealSums = async (id: number, requestDTO: object): Promise<TraderRequisitesDTO> => {
        let resp = await axios({
            url: `/requisites/${id}/pay-in-deal-sums`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as TraderRequisitesDTO
    }

    payoutMethodsCreate = async (requestDTO: PayoutMethodUpdateRequestDTO): Promise<TraderPayoutMethodDTO> => {
        let resp = await axios({
            url: `/payout-methods`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as TraderRequisitesDTO
    }

    payoutMethodsUpdate = async (id: number, requestDTO: PayoutMethodUpdateRequestDTO): Promise<TraderPayoutMethodDTO> => {
        let resp = await axios({
            url: `/payout-methods/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as TraderPayoutMethodDTO
    }

    togglePayoutMethodActivity = async (id: number, type: string): Promise<TraderPayoutMethodDTO> => {
        let resp = await axios({
            url: `/payout-methods/${id}/${type}`,
            method: 'PUT',
        })

        return resp.data as TraderRequisitesDTO
    }

    toggleRequisitesActivity = async (id: number, type: string): Promise<TraderRequisitesDTO> => {
        let resp = await axios({
            url: `/requisites/${id}/${type}`,
            method: 'PUT',
        })

        return resp.data as TraderRequisitesDTO
    }

    tradersCreate = async (requestDTO: TradersCreateRequestDTO): Promise<TraderDTO> => {
        let resp = await axios({
            url: `/traders`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as TraderDTO
    }

    accountWithdrawalCreate = async (id: string, requestDTO: AccountWithdrawalRequestDTO): Promise<WithdrawalDTO> => {
        let resp = await axios({
            url: `/accounts/${id}/withdraw`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as WithdrawalDTO
    }

    accountTransferCreate = async (id: string, requestDTO: AccountTransferRequestDTO): Promise<void> => {
        let resp = await axios({
            url: `/accounts/${id}/transfer`,
            data: requestDTO,
            method: 'POST',
        })
    }

    tradersUpdate = async (id: number, requestDTO: TradersUpdateRequestDTO): Promise<TraderDTO> => {
        let resp = await axios({
            url: `/traders/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as TraderDTO
    }

    tradersToggleTraffic = async (id: number, direction: Direction, status: string): Promise<TraderDTO> => {
        let resp = await axios({
            url: `/traders/${id}/${direction}/${status}`,
            method: 'PUT',
        })

        return resp.data as TraderDTO
    }

    deviceToggleBlock = async (id: string,  status: string): Promise<TraderDeviceDTO> => {
        let resp = await axios({
            url: `/trader-devices/${id}/${status}`,
            method: 'PUT',
        })

        return resp.data as TraderDeviceDTO
    }

    usersCreate = async (requestDTO: UserCreateRequestDTO): Promise<UserDTO> => {
        let resp = await axios({
            url: `/users`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as UserDTO
    }

    usersUpdate = async (id: number, requestDTO: UserUpdateRequestDTO): Promise<UserDTO> => {
        let resp = await axios({
            url: `/users/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as UserDTO
    }

    merchantOperatorCreate = async (requestDTO: MerchantOperatorCreateRequestDTO): Promise<MerchantOperatorDTO> => {
        let resp = await axios({
            url: `/merchant-operators`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as MerchantOperatorDTO
    }

    merchantOperatorUpdate = async (id: number, requestDTO: MerchantOperatorUpdateRequestDTO): Promise<MerchantOperatorDTO> => {
        let resp = await axios({
            url: `/merchant-operators/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as MerchantOperatorDTO
    }

    merchantsCreate = async (requestDTO: MerchantCreateRequestDTO): Promise<MerchantDTO> => {
        let resp = await axios({
            url: `/merchants`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as MerchantDTO
    }

    merchantsUpdate = async (id: number, requestDTO: MerchantUpdateRequestDTO): Promise<MerchantDTO> => {
        let resp = await axios({
            url: `/merchants/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as MerchantDTO
    }

    deletePayoutMethod = async (id: number): Promise<void> => {
        await axios({
            url: `/payout-methods/${id}`,
            method: 'DELETE',
        })
    }

    deleteRequisites = async (id: number): Promise<void> => {
        await axios({
            url: `/requisites/${id}`,
            method: 'DELETE',
        })
    }

    createRequisites = async (requestDTO: RequisitesUpdateRequestDTO): Promise<TraderRequisitesDTO> => {
        let resp = await axios({
            url: `/requisites`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as TraderRequisitesDTO
    }

    createInvoice = async (requestDTO: Object): Promise<InvoiceDTO> => {
        let resp = await axios({
            url: `/invoices`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as InvoiceDTO
    }

    tokenGet = async (credentials: any): Promise<AccessTokenDTO> => {
        let resp = await axios({
            url: `/token/get`,
            data: credentials,
            method: 'POST',
            headers: {
                'Authorization': ''
            }
        })

        return resp.data as AccessTokenDTO
    }

    systemSettings = async (): Promise<SystemSettingsDTO> => {
        let resp = await axios({
            url: `/system-settings/all`,
            method: 'GET',
        })

        return resp.data as SystemSettingsDTO;
    }

    updateSystemSettings = async (id: string, requestDTO: SystemSettingsUpdateRequestDTO): Promise<SystemSettingsDTO> => {
        let resp = await axios({
            url: `/system-settings/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as SystemSettingsDTO
    }
}

export const api = new APIClient();